import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference } from 'angularfire2/firestore';
import * as firebase from 'firebase';
import { User } from '../menu/reg/users';
import { AuthService } from './auth.service';
import { Transaction, CheckIn } from './classes';


@Injectable({
  providedIn: 'root'
})
export class DbService {

  private currentUser: firebase.User;

  private IDLookup: CollectionReference;
  private EmailLookup: CollectionReference;
  private users: CollectionReference;
  private checkins: CollectionReference;
  private transactions: CollectionReference;
  private balances: CollectionReference;

  constructor(
    private fireStore: AngularFirestore,
    private authService: AuthService
  ) { 
    this.IDLookup = firebase.firestore().collection("id_lookup");
    this.EmailLookup = firebase.firestore().collection("email_lookup");
    this.users = firebase.firestore().collection("users");
    this.checkins = firebase.firestore().collection("checkins");
    this.transactions = firebase.firestore().collection("transactions");
    this.balances = firebase.firestore().collection("balances");

  }

  async getEmailForID(id){
    // console.log("getEmailForID", id);
    return await this.IDLookup.doc(id).get();
  }

  setEmailForID(id, email){
    // console.log("setEmailForID", id, email);
    return this.IDLookup.doc(id).set({email: email, registered: this.authService.firebaseUser.value.email, date: new Date().toLocaleString() });
  }

  getIDForEmail(email){
    // console.log("getIDForEmail", email);
    return this.EmailLookup.doc(email).get();
  }
  
  async setIDforEmail(email, id){
    // console.log("setIDforEmail", email, id);
    return this.EmailLookup.doc(email).set({id: id, registered: this.authService.firebaseUser.value.email, date: new Date().toLocaleString()  });
  }

  async getUserForEmail(email){
    // console.log("getUserForEmail", email);
    let data = (await this.getIDForEmail(email)).data();
    if(data){
      return await this.getUserForID(data["id"]);
    }else{
      return null;
    }
  }

  setUserForID(id, user){
    // console.log("setUserForID", id, user);
    return this.users.doc(id).set(user);
  }

  getUserForID(id){
    // console.log("getUserForID", id);
    return this.users.doc(id).get().then(user => {
      // console.log("getUserForID", user.data());
      return <User>user.data();
    }, err => {
      // console.log("getUserForID ERROR", err);
      return null;
    });
  }


  async addCheckin(id, event_name, type){
    let checkin:CheckIn = {
      event_name: event_name,
      type: type,
      date:new Date().toLocaleString(),
      registered: this.authService.firebaseUser.value.email,
    }

    await this.checkins.add({id:id, ...checkin});
    await this.users.doc(id).collection("checkins").add(checkin);
  }


  async withdrawMoney(id, amount){
    let transaction: Transaction = {
      amount: -amount,
      date: new Date().toLocaleString(),
      description: "Pénz kivétel",
      registered: this.authService.firebaseUser.value.email,
      type: "MONEY"
    };
    await this.addTransaction(id, transaction);
  }
  
  async depositMoney(id, amount){
    let transaction: Transaction = {
      amount: amount,
      date: new Date().toLocaleString(),
      description: "Pénz befizetés",
      registered: this.authService.firebaseUser.value.email,
      type: "MONEY"
    };
    return await this.addTransaction(id, transaction);
  }

  async addTransaction(id, transaction: Transaction){
    await this.transactions.add({id:id, ...transaction})
    await this.users.doc(id).collection("transactions").add(transaction);
    return await this.incrementBalance(id, transaction.amount);
  }

  async incrementBalance(id, amount){
    let currentBalance = (await this.balances.doc(id).get())
    // console.log("currentBalance", currentBalance);
    if(currentBalance.exists){
      await this.balances.doc(id).update({balance: firebase.firestore.FieldValue.increment(amount), updatedAt:new Date().toLocaleString()});
    }else{
      await this.balances.doc(id).set({balance: firebase.firestore.FieldValue.increment(amount), updatedAt:new Date().toLocaleString()});
    }
  }

  async getCheckinsForID(id){
    return await this.users.doc(id).collection("checkins").get();
  }
  async getTransactionsForID(id){
    return await this.users.doc(id).collection("transactions").get();
  }
  async getBalanceForID(id){
    return (await this.balances.doc(id).get()).data()["balance"];
  }

  async getBalance(id){
    let currentBalance = (await this.balances.doc(id).get()).data()
    let transactions = (await this.users.doc(id).collection("transactions").get()).docs.map(x => x.data()).sort(function(a, b){
      if(a.date < b.date) { return -1; }
      if(a.date > b.date) { return 1; }
      return 0;}
      );

    return {"balance": currentBalance, transactions: transactions}
  }

}
