import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { ModalController } from '@ionic/angular';
import { DRINKS } from '../drinks';
import { DbService } from 'src/app/services/db.service';
import { Transaction } from 'src/app/services/classes';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {

  @Input("cart") cart;
  @Input("allAmount") allAmount: number;

  __PULT_ITEMS: any[] = DRINKS;
  my_list = [];

  constructor(
    private modalCtrl: ModalController,
    private helper: HelperService,
    private db: DbService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    Object.keys(this.cart).forEach(id => {
      let prod = this.__PULT_ITEMS.find(x => x.id == id);
      if(this.cart[id]>0){
        this.my_list.push({
          count: this.cart[id],
          name: prod["name"],
          description: prod["description"],
          price: prod["price"]
        })
      }
    });
  }

  async dismiss(){
    await this.modalCtrl.dismiss();
  }

  async checkout(){
    let id = await this.helper.readOneNFC();


    let balance = await this.db.getBalanceForID(id);
    if(balance < this.allAmount){
      this.helper.showAlert("Fizetési hiba", `Nincs elegendő egyenlege a művelet végrehajtásához (Egyenleg: ${balance} Ft)`)
    }else{
      let transaction: Transaction = {
        date: new Date().toLocaleString(),
        amount: -this.allAmount,
        registered: this.authService.firebaseUser.value.email,
        description: JSON.stringify(this.my_list),
        type: "PULT"
      }
      let info = this.db.addTransaction(id, transaction);
      console.log("Sikeres fizetés", id, this.allAmount, balance-this.allAmount, this.my_list, info)
      this.helper.showToast(`Sikeres fizetés: ${id} ${this.allAmount} Ft; ÚJ EGYENLEG: ${balance-this.allAmount} Ft`);
      this.modalCtrl.dismiss(true)
    }
  }

}
