import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  email:string = "rabigabor@gmail.com";
  password: string;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {}

  signIn(){
    let email = (<HTMLInputElement>document.querySelector("#email input")).value;
    let password = (<HTMLInputElement>document.querySelector("#password input")).value;

    // console.log(email, password);
    this.authService.signIn(email, password).then(user => {
      // console.log("signIn", user);
    }, err => {
      // console.log("signIn ERROR", err);
    });
  }


}
