import { Injectable } from '@angular/core';

import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(

  ) { }

  async set(key, value) {
    await Storage.set({
      key: key,
      value: JSON.stringify(value)
    });
  }
  
  async get(key) {
    const { value } = await Storage.get({ key: key });
    return JSON.parse(value);
  }

  async removeItem(key) {
    await Storage.remove({ key: key });
  }
  async clear() {
    await Storage.clear();
  }
}
