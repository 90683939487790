import { Component, OnInit, NgZone } from '@angular/core';
import { NFC, Ndef, NfcTag } from '@ionic-native/nfc/ngx';
import { NfcService } from 'src/app/services/nfc.service';
import { HelperService } from 'src/app/services/helper.service';
import { Food } from 'src/app/services/classes';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-kaja',
  templateUrl: './kaja.component.html',
  styleUrls: ['./kaja.component.scss'],
})
export class KajaComponent implements OnInit {

  lastReadNFCID: string;
  numOfReaden: number = 0;

  selectedFood: string;

  FOODS:Food[] = [
    {
      event_name:"Péntek vacsora",
      key: "eat_friday_dinner"
    },
    {
      event_name:"Szombat reggeli",
      key:"eat_saturday_breakfast"
    },
    {
      event_name:"Szombat ebéd",
      key:"eat_saturday_lunch"
    },
    {
      event_name:"Szombat vacsora",
      key:"eat_saturday_dinner"
    },
    {
      event_name:"Vasárnap reggeli",
      key:"eat_sunday_breakfast"
    },
    {
      event_name:"Vasárnap ebéd",
      key:"eat_sunday_lunch"
    },
    {
      event_name:"Vasárnap vacsora",
      key:"eat_sunday_dinner"
    },
    {
      event_name:"Hétfő reggeli",
      key:"eat_monday_breakfast"
    },
    {
      event_name: "Borkóstoló",
      price: -500
    }
  ]

  isStarted:boolean = false;

  constructor(
    private nfc: NFC, 
    private ndef: Ndef,
    private ngZone: NgZone,
    private helper: HelperService,
    private db: DbService
  ) { }

  ngOnInit() {}

  ionViewWillEnter(){
    // if(this.helper.isCordovaAvailable()){
    //   let flags = this.nfc.FLAG_READER_NFC_A | this.nfc.FLAG_READER_NFC_V;
    //   this.nfc.readerMode(flags).subscribe(
    //     tag => {
    //       this.ngZone.run(() =>{
    //         this.lastReadNFCID = this.nfc.bytesToHexString(tag.id);
    //         this.numOfReaden += 1;
    //       })
    //     },
    //     err => {
    //       console.log('Error reading tag', err)
    //     });
    // }
  }

  async startCheckin(){
    this.isStarted = true;
    this.selectedFood = (<HTMLSelectElement>document.querySelector("#foodSelector")).value;
    let selected:Food = this.FOODS.find(x => x.event_name == this.selectedFood);
    
    console.log(selected);
    let id = await this.helper.readOneNFC();

    while(id){

      let user = (await this.db.getUserForID(id));
      // console.log("current ID", id, user, selected.key);
      if(selected.key){
        console.log("IS OKAY", user[selected.key], user.food_kind);
      }
      

      id = await this.helper.readOneNFC();
    }
    this.isStarted = false;
  }

}
