import { Component, OnInit, Input } from '@angular/core';
import { User } from '../users';
import { ModalController } from '@ionic/angular';
import { HelperService } from 'src/app/services/helper.service';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-chooser',
  templateUrl: './chooser.component.html',
  styleUrls: ['./chooser.component.scss'],
})
export class ChooserComponent implements OnInit {

  @Input("user") user:User;

  constructor(
    private modalCtrl: ModalController,
    private helper: HelperService,
    private db: DbService
  ) { }

  ngOnInit() {}

  async dismiss(){
    await this.modalCtrl.dismiss();
  }

  async registerUser(){
    let id = await this.helper.readOneNFC();

    let existingEmail = (await this.db.getEmailForID(id)).data();
    // console.log("existingEmail",existingEmail)
    if(existingEmail){ this.helper.showAlert("Regisztrációs hiba", `Az ID (${id}) már foglalt: ${JSON.stringify(existingEmail)}`); return;}

    let existingID = (await this.db.getIDForEmail(this.user.email)).data();
    // console.log("existingID",existingID)
    if(existingID){ this.helper.showAlert("Regisztrációs hiba", `Az email (${this.user.email}) már regisztrálva van: ${JSON.stringify(existingID)}`); return;}

    await this.db.setIDforEmail(this.user.email, id);
    await this.db.setEmailForID(id, this.user.email);
    await this.db.setUserForID(id, this.user);
    this.helper.showToast(`${this.user.email} regisztrálva lett (${id})`)
    console.warn(`${this.user.email} regisztrálva lett (${id})`)
    this.dismiss();
  }

}
