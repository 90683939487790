import { Component, OnInit, HostListener } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CheckoutComponent } from './checkout/checkout.component';
import { DRINKS } from './drinks';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  BACKSPACE = 8,
  NUM1 = 49,
  NUM2 = 50,
  NUM3 = 51,
  NUM4 = 52,
  NUM5 = 53,
  NUM6 = 54,
  NUM7 = 55,
  NUM8 = 56,
  NUM9 = 57,
}
@Component({
  selector: 'app-pult',
  templateUrl: './pult.component.html',
  styleUrls: ['./pult.component.scss'],
})
export class PultComponent implements OnInit {

  __PULT_ITEMS: any[] = DRINKS;
  pult_items = [];

  cart = {};

  allAmount = 0;
  allCount = 0;

  currentParent:string = null;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.refreshList();
  }
  vissza(){
    console.log(this.currentParent);
    // console.log("vissza", this.currentParent.substring(0, this.currentParent.lastIndexOf(".")))

    this.refreshList();
  }

  clear(){
    this.currentParent = null;
    this.refreshList();
  }

  chooseItem(item){
    console.log("Choosen item:", item, );
    if(item.id.toString().length <= 4){
      this.currentParent = item.id;
      this.refreshList();
    }
  }

  refreshList(){
    this.pult_items = this.__PULT_ITEMS.filter( x => x.parent == this.currentParent);
  }

  refreshCart(){
    let amount = 0;
    let tetel = 0;
    Object.keys(this.cart).forEach(id => {
      tetel += this.cart[id];
      amount += this.__PULT_ITEMS.find(x => x.id == id)["price"] * this.cart[id];
    });
    this.allAmount = amount;
    this.allCount = tetel;
  }

  plus(item){
    if(this.cart[item.id]) this.cart[item.id] += 1;
    else this.cart[item.id] = 1;
    this.refreshCart();
  }

  minus(item){
    if(this.cart[item.id] && this.cart[item.id]>0){
      this.cart[item.id] -= 1;
    }
    this.refreshCart();
  }

  async checkout(){
    let modal = await this.modalCtrl.create({
      component: CheckoutComponent,
      componentProps:{
        cart: this.cart,
        allAmount: this.allAmount
      }
    });
    modal.onDidDismiss().then(value => {
      console.log("Checkout", value);
    });
    await modal.present();
    await modal.onDidDismiss().then(value => {
      console.log("value", value)
      if(value["data"]){
        this.clearCart();
      }
    })
  }

  clearCart(){
    this.cart = {}
    this.refreshCart();
  }


  // @HostListener('window:keyup', ['$event'])
  // keyEvent(event: KeyboardEvent) {
  //   console.log(event);

  //   if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
  //     this.increment();
  //   }

  //   if (event.keyCode === KEY_CODE.LEFT_ARROW) {
  //     this.decrement();
  //   }
  //   event.keyCode
  // }
}
