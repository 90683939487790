import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';

import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth.service';
import { User } from './menu/reg/users';
import { DbService } from './services/db.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  email: string;
  userData: User;
  public selectedIndex = 0;
  public appPages = [
    // {
    //   title: 'Személyes',
    //   url: '/menu/personal',
    //   icon: 'person-circle'
    // },
    {
      title: 'Pult',
      url: '/menu/pult',
      icon: 'beer'
    },
    {
      title: 'Étkezés',
      url: '/menu/kaja',
      icon: 'pizza'
    },
    {
      title: 'Programok',
      url: '/menu/checkin',
      icon: 'checkbox'
    },
    {
      title: 'Regisztráció',
      url: '/menu/reg',
      icon: 'key'
    },
    {
      title: 'Bank',
      url: '/menu/bank',
      icon: 'cash'
    }
  ];

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private authService: AuthService,
    private db: DbService,
    private router: Router
  ) {
    this.initializeApp();

    authService.firebaseUser.subscribe(async user => {
      console.log("appcomponent", user)
      if(user){
        this.email = user.email;
        router.navigateByUrl("menu/pult");
        this.userData = await db.getUserForEmail(user.email);
      }else{
        this.email = null;
        this.userData = null;
        router.navigateByUrl("login");
      }
    });
  }


  logout(){
    this.authService.signOut();
  }
  


  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      SplashScreen.hide();
    });
  }

  ngOnInit() {
    // const path = window.location.pathname.split('menu/')[1];
    // if (path !== undefined) {
    //   this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    // }
  }
}
