import { Injectable } from '@angular/core';
import { Platform, AlertController, ToastController } from '@ionic/angular';

import { Plugins } from '@capacitor/core';

const { Modals, Toast } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private platform: Platform,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController
  ) { }

  
  async readOneNFC(){
    if(this.isCordovaAvailable()){
      // console.log("TODO");
    }else{
      let promptRet = await Modals.prompt({
        title: 'NFC UUID',
        message: 'NFC UUID'
      });
      if(promptRet.cancelled) return null;
      let translated = this.translateNFC(promptRet.value);
      // console.log("translated", translated);
      return translated;
    }
  }

  translateNFC(shortVersion){
    return shortVersion;
  }
  
  isCordovaAvailable(){
    // console.log("platforms", this.platform.platforms())
    return !this.platform.is("mobileweb") && !this.platform.is("desktop");
  }

  async showAlert(title, message){
    let alert = await this.alertCtrl.create({
      header:title,
      message:message,
      buttons:["OK"]
    });
    await alert.present();
  }

  async showToast(message) {
    // await Toast.show({
    //   text: message
    // });
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }


}
