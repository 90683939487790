import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { DbService } from 'src/app/services/db.service';
import { Transaction } from 'src/app/services/classes';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss'],
})
export class BankComponent implements OnInit {

  transactions: any[] = [];
  balance: number = 0;

  constructor(
    private helper:HelperService,
    private db: DbService,
    private authService: AuthService
  ) { }

  ngOnInit() {}

  async doBank(){
    let amount:number = parseInt((<HTMLInputElement>document.querySelector("#inputAmount input")).value)
    if(amount>0){
      let id = await this.helper.readOneNFC();
      await this.db.depositMoney(id, amount)
      this.helper.showToast(`Befizetés: ${id}, ${amount}`)
      console.warn("Befizetés", id, amount)
      this.doGetBalance(id);
    }else if(amount<0){
      let id = await this.helper.readOneNFC();
      await this.db.withdrawMoney(id, -amount)
      this.helper.showToast(`Kifizetés: ${id}, ${amount}`)
      console.warn("Kifizetés", id, amount)
      this.doGetBalance(id);
    }else{
      console.error(`Nem lehet 0 a befizetett összeg (${amount})`)
    }
  }

  async doGetBalance(id){
    let email = (await this.db.getEmailForID(id)).data()["email"];
    let info = await this.db.getBalance(id);

    this.transactions = info["transactions"];
    this.balance = info["balance"]["balance"];
    console.warn("Egyenleglekérdezés", email, info["balance"]["balance"], info["transactions"]);
    this.helper.showToast(`Egyenleg: ${this.balance} Ft`);
    // this.helper.showAlert("Egyenleglekérdezés",`<h2>${email}</h2><h2>${info["balance"]["balance"]}</h2>`);
  }

  async getBalance(){
    let id = await this.helper.readOneNFC();
    this.doGetBalance(id);
    
  }

  torles(){
    this.transactions = [];
    this.balance = 0;
  }


  async giftMoney(){
    let emails = [{"email": "grund.bori@gmail.com", "vegleges": 754}, {"email": "necsengeemese@gmail.com", "vegleges": 1254}, {"email": "mr.balazsbesenyei@gmail.com", "vegleges": 500}, {"email": "gatfalvi.eszter@gmail.com", "vegleges": 1787}, {"email": "belanyitrai94@gmail.com", "vegleges": 1287}, {"email": "hobotpeterbence@gmail.com", "vegleges": 500}];
    for (let index = 0; index < emails.length; index++) {
      try{
        const element = emails[index];
        let data = (await this.db.getIDForEmail(element["email"])).data();
        console.log(data);
        if(data){
          let id = data["id"];
          if(id){
            console.log("giftMoney3", element, id);
            let trans: Transaction = {
              amount: element["vegleges"],
              date: new Date().toLocaleString(),
              description: "Részvételi díj korrekció",
              registered: this.authService.firebaseUser.value.email,
              type: "GIFT3"
            }
            await this.db.addTransaction(id, trans);
          }
        }
      }catch{
        
      }
    }
    
  }

}
