import { Component, OnInit } from '@angular/core';

import {DbService} from '../../services/db.service'
import { User, USERS } from './users';
import { ModalController } from '@ionic/angular';
import { ChooserComponent } from './chooser/chooser.component';

@Component({
  selector: 'app-reg',
  templateUrl: './reg.component.html',
  styleUrls: ['./reg.component.scss'],
})
export class RegComponent implements OnInit {

  ALL_USERS: User[];

  keyword: string = "";

  filtered_list: User[];

  constructor(
    private db: DbService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.ALL_USERS = USERS;
  } 

  pushButton(){
    this.db.setEmailForID("asdasd", "rabigabor@gmail.com");
  }

  onSearchChange($event){
    // console.log(this.keyword, event.detail.value)
    // event.
    if($event.detail.value.length>3){
      let new_keyword = $event.detail.value.toLowerCase();
      // console.log("new_keyword", new_keyword);
      this.filtered_list = this.ALL_USERS.filter(x => (x.name && x.name.toLowerCase().includes(new_keyword)) || (x.email && x.email.toLowerCase().includes(new_keyword)));
      // console.log(this.ALL_USERS.length, this.filtered_list.length)
    }
  }

  async chooseUser(user:User){
    let modal = await this.modalCtrl.create({
      component:ChooserComponent,
      componentProps:{
        user: user
      }
    });

    await modal.present();
  }

}
