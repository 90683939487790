import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { User } from '../reg/users';
import { DbService } from 'src/app/services/db.service';
import { CheckIn, Transaction } from 'src/app/services/classes';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss'],
})
export class PersonalComponent implements OnInit {
  user: User;
  checkins: any[];
  transactions: any[];
  balance: any;

  constructor(
    private authService: AuthService,
    private db: DbService
  ) {

    authService.firebaseUser.subscribe(async user => {
      console.log("PersonalComponent", user)
      if(user){
        let id = (await db.getIDForEmail(user.email)).data()["id"];
        this.user = await db.getUserForEmail(user.email);
        this.checkins = (await db.getCheckinsForID(id)).docs;
        console.log(this.checkins);
        
        this.transactions = (await db.getTransactionsForID(id)).docs;
        console.log(this.transactions);
        this.balance = (await db.getBalanceForID(id)).data();
        console.log(this.balance);
      }
    });
  }

  ngOnInit() {}

}
