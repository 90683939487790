import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { User } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authState: any;
  firebaseUser: BehaviorSubject<User>;

  constructor(
    public ngFireAuth: AngularFireAuth, 
    public ngZone: NgZone,
    public router: Router
  ) { 
    this.firebaseUser = new BehaviorSubject<User>(null);
    this.authState = this.ngFireAuth.authState;

    this.ngFireAuth.authState.subscribe(user => {
      if(user){
        console.log("user", user);
        this.firebaseUser.next(user);
      }else{
        console.log("not user");
        this.firebaseUser.next(null);
      }
    });
  }

  signIn(email, password) {
    return this.ngFireAuth.auth.signInWithEmailAndPassword(email, password);
  }

  signOut() {
    return this.ngFireAuth.auth.signOut().then(() => {
      this.router.navigate(['login']);
    })
  }



}
